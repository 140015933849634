    /*
    Flaticon icon font: Flaticon
    Creation date: 24/01/2020 08:55
    */

    @font-face {
  font-family: "Flaticon";
  src: url("../assets/font/Flaticon.eot");
  src: url("../assets/font/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../assets/font/Flaticon.woff2") format("woff2"),
       url("../assets/font/Flaticon.woff") format("woff"),
       url("../assets/font/Flaticon.ttf") format("truetype"),
       url("../assets/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../assets/font/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

.flaticon-watch:before { content: "\f100"; }
.flaticon-pin:before { content: "\f101"; }
.flaticon-gift:before { content: "\f102"; }
.flaticon-money:before { content: "\f103"; }
.flaticon-calendar:before { content: "\f104"; }
.flaticon-cancel:before { content: "\f105"; }
.flaticon-check:before { content: "\f106"; }
.flaticon-alert:before { content: "\f107"; }
    
    $font-Flaticon-watch: "\f100";
    $font-Flaticon-pin: "\f101";
    $font-Flaticon-gift: "\f102";
    $font-Flaticon-money: "\f103";
    $font-Flaticon-calendar: "\f104";
    $font-Flaticon-cancel: "\f105";
    $font-Flaticon-check: "\f106";
    $font-Flaticon-alert: "\f107";