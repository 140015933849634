body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}


.r-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba($color: #000000, $alpha: 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  &__cap {
    position: absolute;
    top: -16px;
    width: 55px;
    left: -13px;
    z-index: 1;
    animation: toss-cap 1.1s linear infinite;
  }
  &__reculta {
    width: 60px;
    animation: toss-reculta 1.1s linear infinite;
  }
}


.error-bar {
  position: fixed;
  z-index: 100000;
  background-color: #fecfc4;
  color: #fa3709;
  width: 100%;
  box-shadow: 0 0 4px 0px $colorGrey4;
  top: 0%;
  left: 0%;
}

.success-bar {
  position: fixed;
  z-index: 100000;
  background-color: #7dfc7d;
  color: #005c1c;
  width: 100%;
  box-shadow: 0 0 4px 0px $colorGrey4;
  top: 0%;
  left: 0%;
}

.error-line:after {
}

.close-bn {
  font-size: 1.6rem;
  font-weight: 800;
  position: absolute;
  
  right: 10px;
  top: calc(50% - 15px);
}

.r-error {
  border: 1px solid $colorError;
  padding: 5px 10px;
  position: relative;
  &::after {
    content: "\2716";
    position: absolute;
    color: $colorError;
    right: 5px;
    font-size: 0.8em;
    font-weight: 600;
    border: 1px solid $colorError;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.login_img {
  width:60px;
  border-radius:30px;
  height:60px
}

.login_img2 {
  width:80px;
  borderRadius:40px;
  height:80px;
  }
.login_img3 {
  height:17px;
  font-weight:900;
  }
.yellowtag1 {
  padding:20px;

  }
  .yellowtag2 {
  font-weight: 400;
    color: white;
    
  }
   .image2 {
    background:whitesmoke;
    padding-bottom:8px;
    padding:50px;
  }
  .otpheader {
  font-weight:400;   
  }
  .otpheader1 {
  padding-top:30px;   
  }
  .otpheader2 {
  border-bottom:1px solid grey; 
  paddingBottom:1px;
  width:100% ;
  }
  .otpheader3 {
  border:none;
  outline: none;
  width: 100%
  }
  .otpheader4 {
  width:100%;
  border-radius:8px;
  background:#e4e3e2;
  cursor:pointer;
   padding:8px ;
  }
  .otpheader5{
    width:100%;
    background:#e40001;
    border-radius:8px;
    color:white;
    padding:8px;
  }
  .otpheader6{
   font-weight:600;
   color:red;
   cursor:pointer;
  }
  .otpheader7{
    width:150px;
    background:#e40001;
    border-radius:8px;
    color:white;
    padding:8px;
  }
  .vrfheader1{
    padding-top:80px;
    padding-left:30px;

  }
  .vrfHeader{
    font-weight:350;

  }
 .vrfBase{
  width:250px;
  background:#e40001;
  border-radius:8px;
  color:white;
  padding:8px;
 }
 .tickimg{
  text-align:center;
  background:#4ab543;
  width:110px;
  border-radius:55px;
  height:110px;
  padding:15px;
 }
  .clock1 {
    height:24px;
    
  }
  .clock2 {
    height:40px;
    padding-bottom:16px;
  
  }
  .applybutton {
    text-align:center;
    height: 50px;
    background:rgb(228, 0, 1);
    width:100%;
    font-weight:400;
    padding-right:25px;
    color:white;
    cursor:pointer;
    position:fixed;
    bottom:0;
    border:#e40001;
   
  }
  .clock{
    padding-top:80px;
    padding-left:38px;
  }
  

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.mainotp2{
  width:100%;
  border:none;
  outline:none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.share {
  font-weight: bold;
  position: relative;
}
.share:before {
  content: ".";
  font-size: 1.3em;
  position: absolute;
  bottom: -1.6px;
  left: -2.5px;
}
.share:after {
  content: ":";
  font-size: 1.3em;
  position: absolute;
  bottom: -4.6px;
  right: -2px;
}



.hide-scroll::-webkit-scrollbar {
  display: none;
}


.list-spacing ul {
  padding-left: 20px;
}
.list-spacing ol {
  padding-left: 20px;
}

.modal-bottomsheet {
  position: absolute!important;
  bottom: 70px;
  border-top-left-radius: 25px!important;
  border-top-right-radius: 25px!important;
}

.job-info-list-style ul{
  list-style: none;
  padding-left: 15px;
}

.job-info-list-style ul li::before{
  content: "\2713"; 
  color: rgb(10, 148, 0); 
  font-weight: bold; 
  display: inline-block; 
  margin-right: 5px;
}


.touch_scroll {
  -webkit-overflow-scrolling: touch;
}

body {
  &.modal-open {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
  }
}

.tidot {
  animation: mercuryTypingAnimation 1.2s infinite ease-in-out;
  border-radius: 3px;
  display: inline-block;
  height: 5px;
  background-color: #666;
  margin-left: 3px;
  margin-top: 12px;
  margin-right: 2px;
  width: 5px;
}

@keyframes mercuryTypingAnimation{
0%{ -webkit-transform:translateY(0px) }
28%{ -webkit-transform:translateY(-7px) }
44%{ -webkit-transform:translateY(0px) }
}
.tidot:nth-child(1){ animation-delay:100ms; }
.tidot:nth-child(2){ animation-delay:200ms; }
.tidot:nth-child(3){ animation-delay:300ms; }